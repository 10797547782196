import React from "react";
import { FaCheckCircle, FaPlus } from "react-icons/fa";
import { Link } from "react-router-dom";

const AboutAreaTwo = () => {
  return (
    <>
      {/* =============== About Area Two End ===============*/}
      <div className="about-area pd-top-120 pd-bottom-120">
        <div className="container">
          <div className="row">
            <div
              className="col-lg-6 mb-4 mb-lg-0 "
              data-aos="fade-right"
              data-aos-delay="100"
              data-aos-duration="1500"
            >
              <div className="about-thumb-inner">
                <img
                  className="main-img"
                  src="assets/img/about/10.png"
                  alt="img"
                />
              </div>
            </div>
            <div
              className="col-lg-6 "
              data-aos="fade-left"
              data-aos-delay="100"
              data-aos-duration="1500"
            >
              <div className="section-title mb-0">
                <h6 className="sub-title">Representação</h6>
                <h2 className="title">Modulo de Representação</h2>
                <p className="content mb-4">
                  Destaque-se no mercado com nosso Módulo de Representação.
                  Gerencie suas equipes de vendas, amplie sua rede de
                  distribuição e acompanhe o desempenho de cada representante de
                  forma eficiente. Com recursos poderosos de rastreamento de
                  vendas, relatórios em tempo real e análise de dados, você terá
                  uma visão abrangente do seu processo de representação. Aumente
                  sua produtividade, expanda sua presença e alcance novos
                  clientes de maneira estratégica.
                </p>
                <div className="row">
                  <div className="col-md-6">
                    <ul className="single-list-inner style-check style-heading style-check mb-3">
                      <li>
                        <FaCheckCircle /> Suporte dedicado
                      </li>
                      <li>
                        <FaCheckCircle /> Facilidade de uso
                      </li>
                    </ul>
                  </div>
                  <div className="col-md-6">
                    <ul className="single-list-inner style-check style-heading style-check mb-3">
                      <li>
                        <FaCheckCircle /> Funciona offline
                      </li>
                      <li>
                        <FaCheckCircle /> Fácil integração
                      </li>
                    </ul>
                  </div>
                </div>
                <p className="content">
                  Experimente a simplicidade, qualidade e eficiência do nosso
                  Módulo de Representação e eleve seus resultados de vendas para
                  o próximo nível.
                </p>
                <Link
                  className="btn btn-border-base"
                  to="https://bit.ly/3rKZQTB"
                  target="_blank"
                >
                  Faça um orçamento
                  <FaPlus />
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* =============== About Area Two End ===============*/}
    </>
  );
};

export default AboutAreaTwo;
